import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";

// Pages
import OrderSuccess from "./pages/OrderSuccess";
import AboutUs from "./pages/AboutUs";
import OurOffice from "./pages/OurOffice";
import OurClients from "./pages/OurClients";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import CaseStudy from "./pages/CaseStudy";
import CaseDetails from "./pages/CaseStudyDetails";
import Portal from "./pages/Portal";
import Demo from "./pages/Demo";
import Checkout from "./pages/Checkout";
import PricingTable from "./pages/PricingTable";
import Activate from "./pages/Activate";
import ErrorPage from "./pages/404";
import ComingSoon from "./pages/ComingSoon";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import CallbackPage from "./pages/callback-page";
import Blog from "./pages/BlogGrid";
import BlogDetails from "./pages/BlogDetails";
import Category from "./pages/Category";
import DoorAlarm from "./pages/ModeDoor";

//onesignal js
import InitializeOneSignal from "./utils/InitializeOneSignal";

// Css Import
import "./assets/scss/app.scss";
import PanicAlarm from "./pages/ModePanic";
import TempAlarm from "./pages/ModeTemp";

const App = () => {
  return (
    <div>
      <InitializeOneSignal />
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path={process.env.PUBLIC_URL + "/"} element={<AboutUs />} />
            {/* Pages  */}
            <Route
              path={process.env.PUBLIC_URL + "/about-us"}
              element={<AboutUs />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/order-success"}
              element={<OrderSuccess />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/our-office"}
              element={<OurOffice />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/our-clients"}
              element={<OurClients />}
            />
            <Route path={process.env.PUBLIC_URL + "/team"} element={<Team />} />
            <Route
              path={process.env.PUBLIC_URL + "/team-details/:slug"}
              element={<TeamDetails />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/case-study"}
              element={<CaseStudy />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/case-details/:slug"}
              element={<CaseDetails />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/portal"}
              element={<Portal />}
            />
            <Route path={process.env.PUBLIC_URL + "/demo"} element={<Demo />} />
            <Route
              path={process.env.PUBLIC_URL + "/checkout"}
              element={<Checkout />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/checkout"}
              element={<PricingTable />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/activate"}
              element={<Activate />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/404"}
              element={<ErrorPage />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/coming-soon"}
              element={<ComingSoon />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/privacy-policy"}
              element={<PrivacyPolicy />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/terms-use"}
              element={<TermsOfUse />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/callback"}
              element={<CallbackPage />}
            />
            <Route path={process.env.PUBLIC_URL + "/blog"} element={<Blog />} />
            <Route
              path={process.env.PUBLIC_URL + "/blog-details/:slug"}
              element={<BlogDetails />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/category/:slug"}
              element={<Category />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/Panic-Alarm"}
              element={<PanicAlarm />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/Door-Alarm"}
              element={<DoorAlarm />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/Temperature-Alarm"}
              element={<TempAlarm />}
            />
          </Routes>
        </ScrollToTop>
      </Router>
    </div>
  );
};

export default App;
